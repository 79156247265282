@media screen and (max-width: 480px) {
  /* General styles */
  body {
    font-size: 14px;
  }

  .content-container {
    padding: 0 10px;
  }

  /* TopNav styles */
  .MuiToolbar-root {
    flex-direction: column;
    padding: 10px 0;
  }

  .MuiToolbar-root a {
    margin: 5px 0;
  }

  /* Main content styles */
  .input-container {
    flex-direction: column;
  }

  .MuiFormControl-root {
    width: 100%;
    margin-bottom: 10px;
  }

  button, .custom-button {
    width: 100%;
    margin: 5px 0;
  }

  /* Routine display styles */
  .routine-content {
    font-size: 14px;
  }

  /* Footer styles */
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .footer-link {
    margin: 5px 0;
  }

  /* AI Assistant styles */
  .chatContainer {
    height: calc(100vh - 200px);
  }

  .messageItem {
    max-width: 90%;
  }

  /* Email collection form styles for mobile */
  .email-collection {
    max-width: 100%;
    padding: 15px;
    margin: 20px 10px;
  }

  .email-collection h3 {
    font-size: 0.9em;
    margin-bottom: 10px;
  }

  .email-collection form {
    flex-direction: column;
    align-items: stretch;
  }

  .email-collection input {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    font-size: 0.9em;
  }

  .email-collection button {
    width: 100%;
    font-size: 0.9em;
    padding: 8px 15px;
  }

  .success-message {
    font-size: 0.9em;
  }
}
.fabButton {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  background-color: #D4A5A5 !important;
}

.fabButton:hover {
  background-color: #C59494 !important;
}

.dialog {
  border-radius: 12px !important;
}

.dialogTitle {
  padding: 16px 24px !important;
  font-size: 24px !important;
  font-weight: bold !important;
  color: #524C4C !important;
  border-bottom: 1px solid #E0E0E0 !important;
}

.dialogContent {
  padding: 20px 24px 0 !important;
}

.textArea {
  width: 100%;
  height: 150px;
  padding: 12px;
  border: 1px solid #D4A5A5;
  border-radius: 8px;
  resize: none;
  box-sizing: border-box;
}

.textArea:focus {
  outline: none;
  border-color: #C59494;
}

.dialogActions {
  padding: 16px 24px !important;
  justify-content: flex-end !important;
}

.cancelButton {
  color: #524C4C !important;
  text-transform: none !important;
  margin-right: 8px !important;
}

.submitButton {
  background-color: #D4A5A5 !important;
  color: #524C4C !important;
  font-weight: bold !important;
  padding: 6px 16px !important;
  text-transform: none !important;
}

.submitButton:hover {
  background-color: #C59494 !important;
}

.snackbar {
  background-color: #D4A5A5 !important;
  color: #524C4C !important;
  font-size: 16px !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;
  min-width: 0 !important;
  max-width: 300px !important;
}

.snackbar > div {
  padding: 8px 0 !important;
  justify-content: center !important;
}

.charCount {
  color: #524C4C;
  text-align: right;
  margin-top: 4px;
}

.feedbackButtonContainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
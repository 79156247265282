body {
  background-color: #FCFCFC; /* Slightly darker off-white background */
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0 20px; /* Adjust top padding to account for TopNav height */
}

.content-container {
  width: 100%;
  max-width: 600px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

button {
  border-radius: 25px;
  padding: 10px 20px;
  width: auto; /* Adjusts width based on content */
  white-space: nowrap; /* Prevents text wrapping */
  margin: 5px; /* Adds margin between buttons */
}

img {
  border-radius: 10px;
}

.MuiInputBase-input {
  padding: 10px 14px;
}

.MuiOutlinedInput-root {
  border-radius: 25px;
  border: 1px solid #524C4C;
}

.MuiSelect-icon {
  right: 10px;
}

.MuiFormControl-root {
  min-width: 120px; /* Adjust the minimum width to fit content */
  flex: 1; /* Allow the form controls to grow and shrink */
}

.MuiChip-root {
  margin: 2px;
}

.MuiButton-root {
  background-color: #D4A5A5;
  color: #fff;
}

.MuiButton-root:hover {
  background-color: #C59494;
}

select {
  width: 200px; /* Adjusted width to fit the content */
  margin: 5px; /* Adds margin between select elements */ 
}

.skin-goals button {
  display: inline-block; /* Ensures buttons are inline */
  margin: 5px;
}

.form-group {
  margin-bottom: 15px; /* Adds margin between form groups */
}

.footer {
  margin-top: auto; /* Pushes the footer to the bottom */
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-button {
  background-color: #D4A5A5 !important; /* Adjust the background color */
  color: #FCFCFC !important; /* Adjust the text color */
  border-radius: 6px !important; /* Adjust the border radius */
  padding: 10px 15px !important; /* Adjust padding */
  border: none !important; /* Remove border */
  cursor: pointer !important; /* Change cursor on hover */
  font-weight: normal !important;  
  margin-top: 15px !important;
}

.custom-button:hover {
  background-color: #C59494 !important; /* Adjust hover background color */
}

.input-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping */
  justify-content: center;
  gap: 10px; /* Increase gap for better spacing */
}

.MuiFormControl-root {
  min-width: 120px;
  flex: 1 1 calc(50% - 10px); /* Allow two items per row on larger screens */
  max-width: calc(50% - 10px);
}

@media (max-width: 600px) {
  .input-container {
    flex-direction: column;
    align-items: stretch;
  }

  .MuiFormControl-root {
    min-width: 100% !important;
    max-width: 100% !important;
    margin-bottom: 10px !important;
    width: 100% !important; /* Ensure the input fields take full width on mobile */
  }

  button, .custom-button, select, .skin-goals button {
    width: 100% !important;
  }

  button {
    padding: 8px !important; /* Further adjust padding for smaller screens */
  }

  .custom-button {
    margin-top: 15px !important;
  }

  .content-container {
    padding: 0 10px;
  }

  .main-wrapper {
    padding: 40px 0 20px;
  }

  /* Adjust the top navigation for mobile */
  .MuiToolbar-root {
    flex-wrap: wrap;
    justify-content: center !important;
    padding: 10px !important;
  }

  .MuiToolbar-root a {
    margin: 5px 8px !important;
  }

  .cl-userButtonBox {
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }

  body, #root {
    padding: 5px;
  }

  .form-group {
    margin-bottom: 8px; /* Further reduce margin between form groups */
  }

  .footer-link {
    display: block;
    margin: 10px 0;
  }
}

@media (min-width: 601px) and (max-width: 840px) {
  .content-container {
    max-width: 80%;
    padding: 0 15px;
  }

  .input-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .MuiFormControl-root {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
    margin-bottom: 15px;
  }

  button, .custom-button, select, .skin-goals button {
    width: auto;
    min-width: 200px;
  }

  .main-wrapper {
    padding: 60px 0 30px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-size: 1.2em;
  }

  .footer {
    max-width: 80%;
  }
}

@media (min-width: 601px) {
  img[alt="Skinloe Logo"] {
    width: 250px;
    margin-bottom: 20px;
  }
}

.footer-link {
  color: #524C4C;
  text-decoration: none;
  margin: 0 10px;
  font-size: 14px;
}

.footer-link:hover {
  color: #CDA2AB;
}

/* Top navigation styles */
.MuiAppBar-root {
  background-color: #FCFCFC !important;
  color: #524C4C !important;
  border-bottom: 1px solid #CDA2AB !important;
}

.MuiToolbar-root {
  justify-content: flex-start !important;
  padding-left: 16px !important; /* Add some padding to the left */
}

.MuiButton-root {
  text-transform: none !important; /* Prevent uppercase transformation */
}

/* Style for the links in the TopNav when signed in */
.MuiToolbar-root a {
  margin-right: 16px;
  font-weight: normal;
  color: #524C4C;
  text-decoration: none;
}

.MuiToolbar-root a:hover {
  color: #CDA2AB;
}

/* Footer styles */
.footer {
  margin-top: auto;
  width: 100%;
  max-width: 600px;
  background-color: #FCFCFC;
  border-top: 1px solid #CDA2AB;
  padding: 20px 0;
  text-align: center;
}

/* UserButton styles */
.cl-userButtonBox {
  margin-right: 16px !important;
}

/* Add these new styles at the end of the file */
.huiddoelen-select {
  position: relative;
}

.huiddoelen-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #CDA2AB;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.75rem;
  font-weight: bold;
}

.huiddoelen-select .MuiSelect-select {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Hide the password visibility toggle button on all devices */
.cl-formFieldInputShowPasswordButton {
  display: none !important;
}

/* Ensure the password field behaves like a standard password input */
.cl-formFieldInput__password {
  -webkit-text-security: disc !important;
}

/* Adjust the password field to match other inputs */
.cl-formField_password {
  width: 100% !important;
}

.cl-formField_password .cl-formFieldInput__password {
  width: 100% !important;
  padding-right: 14px !important; /* Match padding of other inputs */
}

/* Remove any additional wrappers or elements that might be causing issues */
.cl-formFieldInputGroup > *:not(input) {
  display: none !important;
}

/* Add this at the end of the file */
.routine-content a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px dotted currentColor;
  transition: border-bottom 0.3s ease;
}

.routine-content a:hover {
  color: #CDA2AB;
  border-bottom: 1px solid currentColor;
}
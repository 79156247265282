.container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 280px); /* Reverted to original height */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.chatContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow-y: auto;
  border: 2px solid #D4A5A5;
  position: relative;
}

.instructionText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #524C4C;
  font-size: 1.2em;
  opacity: 0.7;
  pointer-events: none;
  width: 80%;
  max-width: 400px;
}

.messageList {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.messageItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  gap: 8px;
}

.userMessageItem {
  justify-content: flex-end;
}

.aiMessageItem {
  justify-content: flex-start;
}

.message {
  padding: 12px;
  border-radius: 12px;
  max-width: 70%;
  margin: 0 8px;
}

.userMessage {
  background-color: #F7F7F7;
  color: #524C4C;
}

.aiMessage {
  background-color: #D4A5A5;
  color: #524C4C;
}

.userAvatar {
  background-color: #D4A5A5 !important;
  order: 1;
}

.aiAvatar {
  background-color: #D4A5A5 !important;
}

.inputBox {
  display: flex;
  padding: 16px;
  background-color: white;
  border-top: 1px solid #e0e0e0;
}

.textField {
  flex: 3 !important;	
  margin-right: 16px !important;
}

.button {
  flex: 1 !important;
  background-color: #D4A5A5 !important;
  color: #524C4C !important;
}

.button:hover {
  background-color: #C59494 !important;
}

/* Custom scrollbar styles */
.messageList::-webkit-scrollbar {
  width: 8px;
}

.messageList::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.messageList::-webkit-scrollbar-thumb {
  background: #D4A5A5;
  border-radius: 4px;
}

.messageList::-webkit-scrollbar-thumb:hover {
  background: #C59494;
}

.feedbackForm {
    background-color: white;
    border: 2px solid #D4A5A5;
    border-radius: 8px;
    padding: 16px;
    margin-top: 16px;
  }

.welcomeMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px;
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  text-align: center;
  max-width: 80%;
  transition: opacity 0.3s ease-in-out;
  border: 1px solid rgba(212, 165, 165, 0.2);
}

/* Mobile styles */
@media screen and (max-width: 600px) {
  .container {
    padding: 8px;
    height: calc(100vh - 200px); /* Reverted to original mobile height */
    position: static;
    transform: none;
    left: auto;
  }

  .chatContainer {
    height: calc(100vh - 250px); /* Reverted to original mobile chat container height */
    border: none;
    box-shadow: none;
  }

  .mobileChatContainer {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }

  .mobileInputBox {
    flex-direction: column;
    padding: 8px;
  }

  .mobileTextField {
    margin-right: 0 !important;
    margin-bottom: 8px;
  }

  .mobileButton {
    width: 100%;
  }

  .message {
    max-width: 85%;
  }

  .welcomeMessage {
    max-width: 90%;
    padding: 16px;
    position: static;
    transform: none;
    margin: 16px auto;
  }
}
.email-collection {
  background-color: transparent;
  border: none;
  padding: 0 10px;
  margin: 0 auto 15px;
  text-align: center;
  max-width: 380px;
  width: 100%;
  box-sizing: border-box;
}

.email-collection h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.1em;
  color: #524C4C;
  font-weight: normal;
}

.email-collection form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.email-collection input {
  flex: 1;
  padding: 10px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
  min-width: 0;
  height: 40px;
  box-sizing: border-box;
}

.email-collection button {
  background-color: #D4A5A5;
  color: #524C4C;
  border: none;
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  height: 40px;
  box-sizing: border-box;
}

.email-collection button:hover {
  background-color: #C59494;
}

.success-message {
  color: #524C4C;
  margin-top: 10px;
  font-size: 1em;
}

@media screen and (max-width: 480px) {
  .email-collection {
    padding: 0 5px;
  }

  .email-collection form {
    flex-direction: column;
    align-items: stretch;
  }

  .email-collection input,
  .email-collection button {
    width: 100%;
    font-size: 16px;
    height: 44px;
  }

  .email-collection button {
    margin-top: 10px;
  }
}